@import "src/helpers/styles/themes";
.skeleton{
    width: calc(100% - 24px);
    gap: 6px;
  display: flex;
  flex-direction: column;
  max-width: 320px;
    .skeleton-line{
      width: 100%;
      border-radius: 4px;
      height: 16px;
      position: relative;
      background: rgba($dark, .04);
      overflow: hidden;
      &:after{
        content: "";
        position: absolute;
        left: 40px;
        height: 48px;
        width: 100px;
        top: -12px;
        animation-name: skeleton-anim;
        animation-iteration-count: infinite;
        animation-duration: 3s;
        background: linear-gradient(90deg, rgba($grey-light,0) 0%, rgba(255,255,255,.3) 49%, rgba($grey-light,0) 100%);
      }

      &.skeleton-1{

      }
      &.skeleton-2{
        width: calc(100% - 100px);
        &:after{
          animation-delay: 1s;
        }

      }
      &.skeleton-3{
        width: calc(100% - 40px);
        &:after{
          animation-delay: 2s;
        }
      }

    }
}

@keyframes skeleton-anim {
  from {left: 0}
  to {left: 100%}
}