.div {
  display: flex;
  &:not(.n-width){
    width: 100%;
  }


  &.pointer {
    cursor: pointer;
    &:hover{
      opacity: .8;
    }
  }

  &.wrap-m {
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  &.wrap-m-c {
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.loading {
    pointer-events: none;
    animation-name: loading-div;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    @keyframes loading-div {
      from {
        opacity: .4
      }
      50% {
        opacity: .7
      }
      to {
        opacity: .4
      }
    }
  }

  &.overflow-hidden {
    overflow: hidden;
    height: 100%;
  }

  &.overflow-hidden-x {
    overflow-x: hidden;
    width: 100%;
  }
  &.overflow-hidden-y {
    overflow-y: hidden;
    width: 100%;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  &.width-full {
    max-width: 1920px;
    width: calc(100% - 48px);
    margin: 0 auto;
  }

  &.page-width {
    max-width: 1260px;
    width: calc(100% - 48px);
    margin: 0 auto;
  }

}