@import "./src/helpers/styles/themes";
.chat-content {
  height: 100%;
  align-items: flex-end;
  overflow: hidden;

  .chat-header{
    background: white;
    top: 0;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 6px;
    .user-invite{
      margin-left: auto;
      width: unset;
    }
  }

  .chat-content-in {
    height: 100%;
    .messages-scroll {
      height: 100%;
      overflow: hidden;
      overflow-y: overlay;

      /* width */
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: white;
      }

      &:hover{

        &::-webkit-scrollbar-track {
          background: $grey-light;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $grey;
        }
      }



/*
      &::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-track {
        background: lightgrey;
        border-radius: 6px;
      }*/

      .messages-scroll-in{
        width: calc(100% - 12px);
        padding: 0 6px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        min-height: 100%;
        justify-content: end;
        .list-item{
          overflow: hidden;
          .list-item-title{
            overflow: hidden;
          }
        }
      }

    }
  }
}

.hidden-file-input{
  display: none;
}

.files-content{
  flex-wrap: wrap;
  gap: 6px;
  overflow: hidden;
  flex-shrink: 0;
  .file-item{
    width: 240px;
    background: $grey-light;
    padding: 6px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    &.media-file{
      width: fit-content;
    }

    .file-item-icon{
      width: 32px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }

    img, video{
      width: 72px;
      height: 72px;
      object-fit: cover;
    }

    .play-icon{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      fill: $main;
    }

    .file-item-text {
      overflow: hidden;
      width: 100%;
      display: block;
      .file-item-title {
        font-weight: bold;
        width: 100%; /* Задайте фиксированную ширину */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
    .file-item-remove{
      display: none;
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    &:hover{
      .file-item-remove{
        display: flex;
      }
    }
  }
}
