@import "./src/helpers/styles/themes";

.call-content{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  background: $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  .call-content-in{
    height: 100%;
    align-items: center;
  }
  .call-status{

  }
  .call-footer{
    padding: 40px 0;
  }
}