$main: #0575F3;
$dark: #232323;
$light: white;
$white:#fff;
$red: #EF1E3B;
$green: #009E5A;
$grey:#BEBEBE;
$grey-light: #F3F3F3;
$green-gradient: linear-gradient(to right, #018E02, #015443);
$yellow: #9a7b0f;
$yellow-gradient: linear-gradient(to right, #9a7b0f, #FFCD1D);
$bg-select: rgba($main, .1);



$themes: (
        light: (
                bg-color: $light,
                tx-100: $dark,
                tx-80: rgba($dark, .8),
                tx-60: rgba($dark, .6),
                tx-10: rgba($dark, .1),
        ),
        dark: (
                bg-color: $dark,
                tx-100: $light,
                tx-80: rgba($light, .8),
                tx-60: rgba($light, .6),
                tx-10: rgba($light, .1),
        ),
);


@mixin them($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}




