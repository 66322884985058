@import "src/helpers/styles/themes";
.uploader{
  width: 120px;
  height: 120px;
  border-radius: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  @include them($themes) {
    background: themed('tx-10');
  };

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  &:hover{
    opacity: .8;
  }

  svg{
    width: 72px;
    height: 72px;
    path{
     @include them($themes) {
        fill: themed('tx-10');
      };
    }
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-cover{
    width: 72px;
    height: 72px;
    background-position: center;
    background-repeat: no-repeat;
  }

  input{
    display: none;
  }
  .progress-bar{
    width: 0%;
    transition: .5s width;
    height: 100%;
    background: rgba($main, 0.5);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}