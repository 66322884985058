@font-face {
    font-family: Mardoto;
    src: local('Mardoto'), url(./../public/fonts/mardoto_regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Mardoto;
    src: local('Mardoto'), url(./../public/fonts/mardoto_medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Mardoto;
    src: local('Mardoto'), url(./../public/fonts/mardoto_bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: Mardoto system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    a {
        text-decoration: none;
        display: contents;
        color: #232323;
    }
}


