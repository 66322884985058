@import "./../../helpers/styles/themes";

.stories-content{
  display: flex;
  gap: 8px;
  padding: 8px;
  position: relative;
  align-items: center;
  overflow: hidden;
  user-select: none;

  .story-button{
    width: 28px;
    height: 28px;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    left: 8px;
    z-index: 2;
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
    svg{
      color: $light;
    }

    &.right{
      right: 10px;
      left: unset;
    }

    &.hidden{
      display: none;
    }

  }

  .story-content-in{
    display: flex;
    gap: 8px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .story-item{
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      border-radius: 30px;
      overflow: hidden;
      cursor: pointer;
      box-sizing: border-box;
      border: 2px solid $grey;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &:hover{
        opacity: .7;
      }


      &.new{
        border-color: $green;
      }
    }

  }

}


.my-story{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 40px;
  overflow: hidden;
  .my-story-button{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
      color: $light;
      width: 20px;
      height: 20px;

    }
    &:hover{
      opacity: .7;
    }
  }
}

.story-show-bg{
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  .uploader {
    svg path{
      fill: $light !important;

    }
  }
  .story-show-close{
    position: absolute;
    right: 12px;
    top: 12px;
    svg{
      color: $light !important;
    }
  }
}

.story-view-content{
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  gap: 24px;

  .story-close-button{
    width: 48px;
    height: 48px;
    border-radius: 40px;
    background: rgba(255,255,255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 12px;
    top: 12px;
    z-index: 9;
    cursor: pointer;

    svg{
      width: 24px;
      height: 24px;
    }

    &:hover{
      background: rgba(255,255,255, 1);
    }
  }

  img{
    width: 300px;
    height: 500px;
    object-position: center;
    object-fit: cover;
    border-radius: 10px;
  }
}

.story-item-img{
  position: relative;
.story-remove-button{
  position: absolute;
  z-index: 9;
  right: 12px;
  bottom: 20px;
}

  .story-view-button{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    color: white;
    width: fit-content;
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
  }

  .story-control-buttons-content {
    width: 436px;
    max-width: calc(100vw - 8px);
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 64px;
    display: flex;
    justify-content: space-between;

    .story-control-button {
      width: 64px;
      height: 64px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 32px;
        height: 32px;
      }
      &:hover{
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
.story-show-info-content{
  position: absolute;
  width: calc(100% - 12px);
  padding: 0 6px;
  left: 0;
  top: 4px;

  .story-show-info-user{
    color: $light;
    font-size: 15px;
    .text{ color: $light}
  }

  .story-show-progress-content {
    height: 3px;
    display: flex;
    gap: 4px;

    .story-progress-item {
      width: 100%;
      height: 100%;
      background: $grey;
      border-radius: 10px;
      position: relative;

      &.active {
        &:after {
          width: 100%;
          height: 100%;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background: $light;
          animation-name: story-progress;
          animation-duration: 5s;
          animation-timing-function: linear;
        }
      }
    }
  }
}
}

@keyframes story-progress {
  from {width: 0}
  to{width: 100%}
}