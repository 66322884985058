@import "./../../helpers/styles/themes";

.layout{
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  gap: 12px;
  overflow: hidden;

  .left-panel{
    width: 280px;
    flex-shrink: 0;
    background-color: white;
    border-radius: 6px;
    max-height: 100%;
    transition: .3s left;
    @media only screen and (max-width: 1140px){
      border-radius: 0 6px 6px 0;
    }
    @media only screen and (max-width: 768px){
      width: 100%;
      border-radius: 0;
    }
  }

  .r-panel{
    width: 280px;
    flex-shrink: 0;
    background-color: white;
    border-radius: 6px;
  }
  .page-content{
    padding: 6px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    height: fit-content;
    overflow-x: hidden;
    height: calc(100vh - 96px);
    @media only screen and (max-width: 1140px){
      border-radius: 6px 0 0 6px;
    }
    @media only screen and (max-width: 768px){
      border-radius: 0;
      width: calc(100% - 12px);
    }
  }

  .shadow-center{
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.no-border{
    .left-panel {
      border: none;
    }
  }

  @media only screen and (max-width: 768px){
    flex-direction: column;
    .left-panel{
      position: fixed;
      width: 100%;
      height: calc(100% - 72px);
      z-index: 99;
      top: 72px;
      left: -100%;
    }
  }

}

body.menu-opened{
  .layout{
    .left-panel{
      left: 0;
    }
  }
}


