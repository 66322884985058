@import "./../../helpers/styles/themes";

.input-container {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  .input {
    display: flex;
    max-width: 100%;
    align-items: flex-end;
    overflow-x: hidden;

    &.disabled {
      .input-content {
        .input-in {
          pointer-events: none;
          opacity: .8;
        }
      }
    }
    &.size-md {
      .input-content .input-in input {
        font-size: 20px;
      }
    }

    .side-label {
      margin-right: 8px;
      line-height: 16px;
      font-size: 14px;
      color: red;
      height: 36px;
      display: flex;
      align-items: center;
    }

    .input-content {
      width: 100%;

      .input-label {
        line-height: 16px;
        font-size: 14px;
        margin-bottom: 4px;
        color: $grey;
        font-weight: 400;
      }

      .input-help {
        line-height: 14px;
        font-size: 10px;
        margin-top: 4px;
        color: $grey;
        position: absolute;
      }

      .input-in {
        padding: 6px 12px 5px 12px;
        box-sizing: border-box;
        background-color: $grey-light;
        border-radius: 18px;
        display: flex;
        width: 292px;
        max-width: 100%;
        align-items: center;

        &:focus-within {
          border-bottom-color: $main;
        }

        .input-icon {
          width: 22px;
          height: 22px;
          margin-right: 4px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.r {
            margin-right: unset;
            margin-left: 4px;
          }

          &.clear {
            cursor: pointer;
          }

          svg {
            width: 20px;
            height: 20px;

            path:not([fill='none']) {
              fill: $grey;
            }
          }
        }

        input {
          width: 100%;
          border: none;
          line-height: 20px;
          font-size: 14px;
          font-family: inherit;
          background-color: transparent;
          color: $dark;

          &::placeholder {
            color: $grey;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type=number] {
            -moz-appearance: textfield;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &.no-border {
      .input-content {
        .input-in {
          border-bottom: unset;
        }
      }
    }

    &.input-sm {
      .side-label {
        font-size: 12px;
        height: 32px;
      }

      .input-content {
        .input-in {
          input {
            line-height: 16px;
            font-size: 12px;
          }
        }
      }
    }

    &.input-lg {
      .side-label {
        height: 40px;
      }

      .input-content {
        .input-in {
          input {
            line-height: 24px;
            font-size: 16px;
          }
        }
      }
    }


    &.input-success {
      .input-content {
        .input-help {
          color: $green;
        }

        .input-in {
          border-color: $green;
        }
      }
    }

    &.input-error {
      .input-content {
        .input-help {
          color: $red;
        }

        .input-in {
          border-color: $red;
        }
      }
    }



    //textarea
    &.textarea {

      .input-content {
        .input-in {
          max-width: 100%;
          width: 560px;
          height: 180px;
          border-radius: 12px;
          textarea{
            height: 100%;
            width: 100%;
            border: none;
            line-height: 20px;
            font-size: 14px;
            font-family: inherit;
            background-color: transparent;
            color: $dark;
            resize: none;
            &::placeholder {
              color: $grey;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type=number] {
              -moz-appearance: textfield;
            }

            &:focus {
              outline: none;
            }
          }

        }
      }

    }



  }
  &.full{
    width: 100%;
    .input .input-content .input-in{
      width: 100%;
    }
  }
}
