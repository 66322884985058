@import "./src/helpers/styles/themes";

.list-item {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  //sizes
  &.size-md {
    padding: 24px 6px;
    min-height: 40px;
    height: 40px;

    .list-item-icon {
      width: 24px;
      height: 24px;

      svg {
        @include them($themes) {
          color: themed('tx-100');
        }
      }
      &.list-item-icon-left {
        margin-left: 6px;
      }

      &.list-item-icon-right {
        margin-right: 6px;
      }
    }
  }

  .list-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  //hover
  &.hover {
    cursor: pointer;

    &:hover {
      @include them($themes) {
        background: themed('tx-10');
      }
    }
  }

  &.hovered {
    @include them($themes) {
      background: themed('tx-10');
    }
  }

  &.selected {
    background: rgba($main, .1);
  }


  .list-item-title {
    padding: 0 6px;
    width: 100%;
    @include them($themes) {
      color: themed('tx-100');
    }
  }


}
