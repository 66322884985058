@import "./src/helpers/styles/themes";

.comments-content{
  height: 100%;
  overflow: hidden;
  .comments-content-in{
    overflow: auto;
  }
}

.comment-item{
  width: fit-content;
  max-width: calc(100% - 32px);
  .comment-item-tx{
    padding: 6px 12px;
    border-radius: 10px;
    background: $grey-light;
  }
}