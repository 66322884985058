@import "src/helpers/styles/themes";
.dialog-cover{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3399;
  display: flex;
  justify-content: center;
  &.center-dialog{
    align-items: center;
    .dialog{
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .dialog{
    margin-top: 48px;
    margin-bottom: 48px;
    width: calc(100% - 72px);
    height: max-content;
    max-height: calc(100% - 120px);
    max-width: 560px;
    padding: 12px 24px 24px 24px;
    @media only screen and (max-width: 768px){
      padding: 12px;
      width: calc(100% - 48px);
    }
    border: 1px solid rgba($light, 0.1);
    position: relative;
    @include them($themes) {
      background: themed('bg-color');
    }
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.no-scroll .dialog-content.scrollbar{
      height: 100%;
    }

    &.size-md{
      max-width: 960px;
    }

    &.size-xsm{
      max-width: 320px;
    }

    .dialog-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
    }

    .dialog-content{
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    &.content-center .dialog-content{ align-items: center }

    .dialog-buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dialog-r-buttons{
        margin-left: auto;
      }
    }

    .dialog-progress-cover{
      width: 100%;
      height: 100%;
      @include them($themes) {
        background: rgba(themed('bg-color'), .8);
      }
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: flex-start;

      .dialog-progress-bar{
        height: 12px;
        transition: .5s width;
        border-radius: 12px;
        background: $main;
      }

    }

  }

}