@import "./../../helpers/styles/themes";
.message-item{
  background: rgba($green, .1);
  width: fit-content;
  max-width: 340px;
  padding: 6px;
  border-radius: 12px;
  gap: 6px;
  position: relative;

  @media only screen and (max-width: 768px){
    max-width: 280px;
  }
  @media only screen and (max-width: 468px){
    max-width: 200px;
  }

  &.my{
    margin-left: auto;
    background: rgba($main, .1);
  }

  .message-item-content{
    word-break: break-all;
    white-space: pre-wrap;
    a{
      color: $main;
    }
  }


  .message-images-content{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    overflow: hidden;

    .message-file-image{
      width: 80px;
      height: 80px;
      background: rgba($grey, .5);
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
      video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
      .play-icon{
        position: absolute;
        fill: $main;
        width: 24px;
        height: 24px;
      }
    }

  }
  .message-menu-button{
    position: absolute;
    right: 6px;
    top: 0;
    display: none;
  }
  &:hover{
    .message-menu-button{
      display: flex;
    }
  }
}

.gallery-content{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    max-width: calc(100% - 48px);
    max-height: calc(100% - 48px);
  }
  video{
    max-width: calc(100% - 48px);
    max-height: calc(100% - 48px);
  }
}

.call-message{
  width: fit-content;
  max-width: calc(100% - 24px);
  background: $grey-light;
  min-width: 220px;

  padding: 0 6px;
  margin: 0 auto;
  border-radius: 6px;
  .call-mess-icon{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{color: $green}
    &.alert{
      svg{color: $red}
    }
  }
  .call-mess-text{
    width: fit-content;
  }
}
