@import "src/helpers/styles/themes";
.avatar{
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $grey-light;

  img{
    width: 36px;
    height: 36px;
    object-fit: cover;
  }

  &.circle{
    border-radius: 48px;
    overflow: hidden;
  }

  &.size-md{
    width: 48px;
    height: 48px;
    img{
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
    svg{
      width: 24px;
      height: 24px;
    }
  }

  &.size-lg{
    border-radius: 72px;
    width: 120px;
    height: 120px;
    img{
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
    svg{
      width: 72px;
      height: 72px;
    }
  }

  &.size-xsm{
    border-radius: 72px;
    width: 24px;
    height: 24px;
    img{
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    svg{
      width: 18px;
      height: 18px;
    }
  }


  svg path{
    @include them($themes) {
      fill: themed('tx-60');
    }
  }

  .text{
    text-transform: uppercase;
  }

}