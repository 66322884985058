@import "./src/helpers/styles/themes";

.scrollbar-component{
  max-height: 100%;
  overflow: hidden;
  overflow-y: overlay;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: white;
  }

  &:hover{

    &::-webkit-scrollbar-track {
      background: $grey-light;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $grey;
    }
  }

}