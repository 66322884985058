@import "./../../helpers/styles/themes";
.header-full{
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(34, 60, 80, 0.2);
  z-index: 9;

  .header{
    width: 100%;
    max-width: 1140px;

    .h-l, .h-r{
      width: 100%;
      @media only screen and (max-width: 768px){
        width: fit-content;
      }
      .cat-button{
        margin-left: auto;
        @media only screen and (max-width: 768px){
          padding-right: 0;
          .button-title-content{
            display: none;
          }
        }
      }

    }
    .h-c{
      width: 100%;
      padding: 0 12px;
      position: relative;
      @media only screen and (max-width: 768px){
        .input-container .input .input-content .input-in{
          width: unset;
        }
      }

      .search-result{
        position: absolute;
        left: 12px;
        top: 36px;
        width: calc(100% - 48px);
        padding: 6px 12px;
        background: white;
        border-radius: 0 0 4px 4px;
        -webkit-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
      }

    }

    .menu-button{
      margin-right: 12px;
      display: none;
      @media only screen and (max-width: 768px){
        display: flex;
      }
    }

  }
}

